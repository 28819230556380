@media screen and (max-width: 400px) {

  #about,
  #services,


  #portfolio {
    width: 110%;
  }
}
